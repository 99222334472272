import {ACTIONS} from '@/constants'

const expensesRoutes = [
  {
    path: '/expenses',
    meta: {
      title: 'Expenses',
    },
    name: 'Expenses',
    redirect: {name: 'ExpensesSubmissions'},
    component: () => import('@/views/expenses/ExpensesView.vue'),
    children: [
      {
        path: '',
        name: 'ExpensesSubmissions',
        component: () => import('@/views/expenses/ExpensesSubmissionsView.vue'),
        children: [
          {
            path: 'new',
            name: 'ExpensesSubmissionsNew',
            component: () => import('@/views/expenses/ExpensesSubmissionsNewView/index.vue'),
            meta: {
              isOverlay: true,
            },
          },
          {
            path: ':submittedReceiptId/edit',
            name: 'ExpensesSubmissionsEdit',
            component: () => import('@/views/expenses/ExpensesSubmissionsEditView.vue'),
            meta: {
              isOverlay: true,
            },
          },
        ],
      },
      {
        path: 'company-receipts',
        name: 'ExpensesCompanyReceipts',
        component: () => import('@/views/expenses/ExpensesCompanyReceiptsView.vue'),
        meta: {
          requiresPermissions: {
            allOf: [ACTIONS.MODIFY_SUBMITTED_RECEIPTS],
          },
        },
        children: [
          {
            path: ':submittedReceiptId/bookings',
            name: 'ExpensesCompanyReceiptsBookings',
            component: () => import('@/views/expenses/ExpensesBookingsView.vue'),
            meta: {
              isOverlay: true,
            },
            children: [
              {
                path: 'edit',
                name: 'ExpensesCompanyReceiptsBookingsEdit',
                component: () => import('@/views/expenses/ExpensesReviewEditView.vue'),
              },
            ],
          },
          {
            path: ':submittedReceiptId/edit',
            name: 'ExpensesCompanyReceiptsEdit',
            component: () => import('@/views/expenses/ExpensesReviewEditView.vue'),
            meta: {
              isOverlay: true,
            },
          },
        ],
      },
      {
        path: 'cash-expenditures',
        name: 'ExpensesCashExpenditures',
        component: () => import('@/views/expenses/ExpensesCashExpendituresView.vue'),
        meta: {
          requiresPermissions: {
            allOf: [ACTIONS.MODIFY_SUBMITTED_RECEIPTS],
          },
        },
        children: [
          {
            path: ':submittedReceiptId/bookings',
            name: 'ExpensesCashExpendituresBookings',
            component: () => import('@/views/expenses/ExpensesBookingsView.vue'),
            meta: {
              isOverlay: true,
            },
            children: [
              {
                path: 'edit',
                name: 'ExpensesCashExpendituresBookingsEdit',
                component: () => import('@/views/expenses/ExpensesReviewEditView.vue'),
              },
            ],
          },
          {
            path: ':submittedReceiptId/edit',
            name: 'ExpensesCashExpendituresEdit',
            component: () => import('@/views/expenses/ExpensesReviewEditView.vue'),
            meta: {
              isOverlay: true,
            },
          },
        ],
      },
    ],
  },
]

export default expensesRoutes
